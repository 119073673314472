<template>

    <div>
        <div class="car-container mt-4" v-if="!this.checkCarAvaiable">
            
            <div class="areaheader">
                <h1>{{ this.trans[selectedLang]['FINANCE CALCULATOR'] }}</h1>
                <p class="txtMuted">{{ this.trans[selectedLang]['Simply select your model and desired grade, set the parameters to your preference and get an estimated monthly payment instantly.'] }}</p>
            </div>

            <ul class="carList">
                <li v-for="(car,index) in allCars()" :key="index">
                    <div v-if="configuredCar[index]" class="car-space">
                        <div @click="selectCar(index)">
                            <img :src="configuredCar[index].image" class="car-img" alt="" />
                            <h5 class="heading text-start">{{ this.selectedLang != 'en' ? configuredCar[index].ar_name : configuredCar[index].display }}</h5>
                            <div class="priceBox">
                                <label class="text-start">{{ this.trans[selectedLang]['Starting price'] }}*</label>
                                <div class="priceIn" :class="this.selectedLang == 'en'?'flex':''">

                                    <span class="text-end">
                                        {{ Number(filterPrice(car.version)).toLocaleString() }}
                                    </span>
                                    <span class="currency-title"> {{ this.trans[selectedLang]['CURRENCY'] }} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>


            <p class="car-discleamer">
                * {{ this.trans[selectedLang]['DISCLAIMER'] }}. 
            </p>

        </div>

        <div class="car-detail-section" v-if="this.checkCarAvaiable">
            <div class="container">
                <div class="row">
                    <div class="col-12 text-right-btn">
                        <button class="backToHome" @click="checkCarAvaiable = false">{{ this.trans[selectedLang]['SELECT A DIFFERENT MODEL'] }}</button>
                    </div>
                </div>
                <div class="headPart">
                    <div class="row">

                        <div class="col-md-3">
                            <div class="gradeMinfo">
                                
                                <div class="detail">
                                    <h1 class="car-grade-">{{ this.specificCarGrade.version_label }}</h1>
                                    <div class="custom-selectedValue">
                                        <span class="span">
                                            {{ Number(this.specificCarGrade.price).toLocaleString() }} <i>{{ this.trans[selectedLang]['CURRENCY'] }}</i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-md-6">
                            <div class="rightDetail">
                                <img :src="this.specificCarGrade.image" />
                            </div>
                        </div>
                        <div class="col-md-3 rightDetail">
                            <div class="gradeMinfo">
                                <!-- <div class="text-center" v-if="!showCarModels">
                                    <button @click="checkCarModel" class="btn2">
                                        {{ this.trans[selectedLang]['SELECT A DIFFERENT MODEL'] }}
                                    </button>
                                </div> -->
    
                                <div class="dropdown">
                                    <button class="dropdownBtnCusomt dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ selectedDropdownModel }}
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li v-for="(specificCar,index) in specificCarVersion" :key="index">
                                            <div v-if="specificCar.active != '0' && specificCar.price">
                                                <button class="dropdown-item" type="button" @click="selectCarGrade(index,'1','2')">{{ specificCar.Version_Label }}</button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="descriptionBox">
                    <div class="row borderBottom align-items-normal-sm">
                        <div class="col-7 col-md-6">
                            <h3 class="main-title">{{ this.trans[selectedLang]['Down Payment'] }} {{ this.model.downpaymentRange }}%</h3>
                        </div>
                        <div class="col-5 col-md-6 text-end">
                            <h5 class="pricing-text">
                                {{ Number(parseFloat(this.downpayment.totalAmount).toFixed(2)).toLocaleString() }}
                                <span>{{ this.trans[selectedLang]['CURRENCY'] }}</span>
                            </h5>
                            <div class="rangerSlider float-end">
                                <input type="range" :min="this.downpayment.min" :max="this.downpayment.max" @change="downPaymentRangeSlider()" v-model="model.downpaymentRange" class="form-range"  id="customRange1">
                            </div>
                        </div>
                    </div>

                    <div class="row borderBottom">
                        <div class="col-7 col-md-6">
                            <h3 class="main-title">{{ this.trans[selectedLang]['TENURE'] }}</h3>
                        </div>
                        <div class="col-5 col-md-6 text-end">
                            <h5 class="pricing-text">
                                {{ this.model.tenureRange }} {{ this.trans[selectedLang]['Months'] }}
                            </h5>
                            <div class="rangerSlider float-end">
                                <input type="range" class="form-range" @change="tenureRangeSlider()" step="12" v-model="model.tenureRange" :min="this.year_tenure_range.min" :max="this.year_tenure_range.max" id="customRange2">
                            </div>
                        </div>
                    </div>
                    
                    <div class="row borderBottom">
                        <div class="col-7 col-md-6">
                            <h3 class="main-title">{{ this.trans[selectedLang]['BALLOON'] }} {{ this.balloonRangePercentage }}%</h3>
                        </div>
                        <div class="col-5 col-md-6 text-end">
                            <h5 class="pricing-text">
                                {{ Number(parseFloat(this.balloonAmount).toFixed(2)).toLocaleString() }}
                                <span>{{ this.trans[selectedLang]['CURRENCY'] }}</span>
                            </h5>
                            <div class="rangerSlider float-end">
                                <input type="range" class="form-range"  step="5" v-model="balloonRangePercentage" :min="this.balloon_range.min" :max="this.balloon_range.max" id="customRange3">
                            </div>
                        </div>
                    </div>
                    
                    <div class="row borderBottom">
                        <div class="col-7 col-md-6">
                            <h3 class="main-title">{{ this.trans[selectedLang]['Extras'] }}</h3>
                        </div>
                        <div class="col-5 col-md-6 text-end">
                            <h5 class="pricing-text">
                                <span>{{ this.trans[selectedLang]['CURRENCY'] }}</span>
                                {{ Number(parseFloat(this.extrasAmount).toFixed(2)).toLocaleString() }}
                            </h5>
                            <div class="rangerSlider float-end">
                                <input type="number" class="form-control" v-model="extrasAmount" min="0" :max="this.balloon_range.max" :placeholder="extrasAmount">
                            </div>
                        </div>
                    </div>

                    <div class="row borderBottom">
                        <div class="col-7 col-md-6">
                            <h3 class="main-title">{{ this.trans[selectedLang]['INSURANCE AMOUNT'] }}</h3>
                        </div>
                        <div class="col-5 col-md-6 text-end">
                            <h5 class="pricing-text">
                                {{ Number(parseFloat(this.insurance.totalAmount).toFixed(2)).toLocaleString() }}
                                <span>{{ this.trans[selectedLang]['CURRENCY'] }}</span>
                            </h5>
                        </div>
                    </div>
                    <div class="row borderBottom">
                        <div class="col-7 col-md-6">
                            <h3 class="main-title">{{ this.trans[selectedLang]['FINANCE AMOUNT'] }}</h3>
                        </div>
                        <div class="col-5 col-md-6 text-end">
                            <h5 class="pricing-text">
                                {{ Number(parseFloat(this.balance_to_finance).toFixed(2)).toLocaleString() }}
                                <span>{{ this.trans[selectedLang]['CURRENCY'] }}</span>
                            </h5>
                        </div>
                    </div>
                    <div class="row borderBottom border-bottom-none">
                        <div class="col-7 col-md-6">
                            <h3 class="main-title">{{ this.trans[selectedLang]['PROFIT RATE'] }}</h3>
                        </div>
                        <div class="col-5 col-md-6 text-end">
                            <h5 class="pricing-text">
                                {{ Number(parseFloat(this.interest_paid).toFixed(2)).toLocaleString() }}
                                <span>{{ this.trans[selectedLang]['CURRENCY'] }}</span>
                            </h5>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-7 col-md-6">
                            <h4 class="emi-heading">
                                <span class="emi-titles">{{ this.trans[selectedLang]['MONTHLY PAYMENT'] }}*</span>
                            </h4>
                        </div>
                        <div class="col-5 col-md-6 text-end">
                            <h4 class="emi-heading">
                                <span class="emi-prices" :class="this.selectedLang == 'en'?'flex':''">
                                    {{ Number(parseFloat(this.monthly_payment).toFixed(2)).toLocaleString() }}
                                    <span> {{ this.trans[selectedLang]['CURRENCY'] }} </span>
                                </span>
                            </h4>
                        </div>
                    </div>

                    <div class="mt-4">
                        <a href="javascript:void(0)" @click="sendFinanceButtonClick" class="btn btn-nissan" >
                            {{ this.trans[selectedLang]['GET A FINANCE QUOTE'] }}
                        </a>
                        <p class="disclaimer mt-2">
                            * {{ this.trans[selectedLang]['TERMS AND CONDITION'] }}
                        </p>
                    </div>
                </div>
            </div>
           
        </div>
        
        <div class="mt-5 pt-5"></div>

    </div>

</template>

<script>

import translatedLang from '../../json/translations.json';
import { adobeAnalyticCode } from "../adobeAnalyticsNew.js";

export default {
    name: 'FinanceCalculator',
    props: {
        newcars: Object,
        vehiclerates: Object,
        carConfiguration: Object
    },
    mounted() {

        this.cars = this.newcars.data.cars;
        // console.log('this cars', Object.values(this.cars))

        // console.log(this.configuredCar)

        this.carRates = this.vehiclerates;
        this.configuredCar = this.carConfiguration.cars_config;

        // Setting up the min and max percentage of Downpayment & Bank Profit Rate.
        this.downpayment.min = parseFloat(this.carRates.downpayment_percentage.min);
        this.downpayment.max = parseFloat(this.carRates.downpayment_percentage.max);

        this.insurance.percentage = parseFloat(this.carRates.insurance_rate);
        this.insurance.tax_rate = parseFloat(this.carRates.insurance_amount_tax_rate);
        this.insurance.minimum_amount = parseFloat(this.carRates.minimum_insurance_amount);
        
        this.year_tenure_range.min = parseInt(this.carRates.year_tenure_range.min);
        this.year_tenure_range.max = parseInt(this.carRates.year_tenure_range.max);
        this.model.tenureRange = parseInt(this.carRates.year_tenure_range.max);
        
        
        this.balloon_range.min = parseInt(this.carRates.balloon_range.min);
        this.balloon_range.max = parseInt(this.carRates.balloon_range.max);
        this.balloonRangePercentage = parseInt(this.carRates.balloon_range.min);
        
        this.annual_interest_rate = parseFloat(this.carRates.annual_interest_rate);
        this.amount_subsidy = parseFloat(this.carRates.amount_subsidy);
        this.admin_fee = parseFloat(this.carRates.admin_fee);
        this.initiation_fee = parseFloat(this.carRates.initiation_fee);

        
        // End - Setting up the min and max percentage of Downpayment & Bank Profit Rate.

        // Looping for Tabs to Set active or not
        // for (const newitem in this.cars) {
        //     for (const grade in this.cars[newitem].version) {
        //         if(this.carRates.PASSENGER.includes(newitem) && this.cars[newitem].version[grade].price && this.cars[newitem].version[grade].active != '0'){
        //             this.tabActiveStatus.passenger = true;
        //         }
        //         if(this.carRates.SUV.includes(newitem) && this.cars[newitem].version[grade].price && this.cars[newitem].version[grade].active != '0'){
        //             this.tabActiveStatus.suvs = true;
        //         }
        //         if(this.carRates.SPORTS.includes(newitem) && this.cars[newitem].version[grade].price && this.cars[newitem].version[grade].active != '0'){
        //             this.tabActiveStatus.sports = true;
        //         }
        //         if(this.carRates.COMMERCIAL_VEHICLES.includes(newitem) && this.cars[newitem].version[grade].price && this.cars[newitem].version[grade].active != '0'){
        //             this.tabActiveStatus.commercial = true;
        //         }
        //     }
        // }
        // Looping for Tabs to Set active or not



        let uri = window.location.search.substring(1); 
        let params = new URLSearchParams(uri);
    
        const selectedModel = params.get("model") ?? '';
        const selectedGrade = params.get("grade") ?? '';

        if(selectedModel !=""){
        //  const models = {           
        //     'maxima':"A36",
        //     'sentra':"B17",
        //     'navara':"D23",
        //     'urvan':"E26",
        //     'micra':"K13",
        //     'altima':"L34",
        //     'sunny':"N17",
        //     'new sunny':"N18",
        //     'kicks':"P15",
        //     'x-terra':"P60A",
        //     'gt-r':"R35",
        //     // 'pathfinder':"R52",
        //     'pathfinder':"R53",
        //     'x-trail':"T32",
        //     'patrol-pickup':"UY61",
        //     'civilian':"W41",
        //     'patrol-safari':"Y61",
        //     'patrol':"Y62",
        //     '370z-coupe':"Z34C",
        //     '370Z-roadster':"Z34R",
        //     'leaf':"ZE1",
        //  }

        

            setTimeout(() => {

                this.selectCar(selectedModel)
                this.selectCarGrade(selectedGrade.replaceAll(" ","_"),'1','0');
            }, 300);
        }

    },
    created() {
        let uri = window.location.search.substring(1); 
        let params = new URLSearchParams(uri);
        // console.log(params.get("lang") ?? 'en');
        this.selectedLang = params.get("lang") ?? 'en';
        if(this.selectedLang != 'en' && this.selectedLang != 'ar'){
            this.selectedLang = 'en';
        }
        this.selectedDropdownModel = this.trans[this.selectedLang]['SELECT A GRADE'];
    },
    computed: {
        
    },
    watch: {
        // 'model.downpaymentRange': function() {
        //     this.calculateMonthlyRepayment();
        // },
        'model.tenureRange': function() {
            this.calculateMonthlyRepayment();
        },
        
        'balloonRangePercentage': function() {
            this.calculateMonthlyRepayment();
        },
        
        'extrasAmount': function() {
            this.calculateMonthlyRepayment();
        },

    },
    data() {
        return {
            tabActiveStatus: {
                passenger:false,
                suvs:false,
                sports:false,
                commercial:false
            },
            showCarModels: true,
            trans: translatedLang,
            selectedLang: null,
            carRates: {},
            cars: {},
            configuredCar: {},
            checkCarAvaiable: false,
            specificCarVersion: {},
            specificCarGrade: {
                'carCategory': null,
                'model': null,
                'vehicle_id': null,
                'key_index': null,
                'version_label': null,
                'price': 'No Price',
                'image': null,
            },
            selectedDropdownModel: '',
            model: {
                downpaymentRange: null,
                tenureRange: null,
            },
            
            insurance: {
                percentage: null,
                tax_rate: null,
                minimum_amount: null,
                totalAmount: null
            },
            downpayment: {
                min: null,
                max: null,
                totalAmount: null
            },
            financeAmount: 0,
            bankProfit: 0,
            year_tenure_range: {
                min: null,
                max: null
            },
            
            balloon_range: {
                min: null,
                max: null
            },
            balloonRangePercentage: 0,
            balloonAmount: 0,

            extrasAmount: 0,
            annual_interest_rate: 0,
            amount_subsidy: 0,
            admin_fee: 0,
            balance_to_finance: 0,
            interest_paid: 0,
            initiation_fee: 0,
            monthly_payment: 0,

            emi: 0,
            gradeCheckAdobe: 0
        }
    },
    methods: {
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        checkCarModel(){
            var mobile = this.isMobile()
            if(mobile){
                this.showCarModels = true;
            }
        },

        allCars() {
            const carKeys = Object.keys(this.cars);
            const filteredCar = {};

            for (const key of carKeys) {
                if (Object.prototype.hasOwnProperty.call(this.configuredCar, key)) {
                    filteredCar[key] = this.cars[key];
                }
            }
            return filteredCar;
        },


        filterPrice(object) {

            for (const key in object) {
                if (Object.hasOwnProperty.call(object, key)) {
                    const element = object[key];
                    if(element.price && element.active == 1)
                        return element.price;
                }
            }
            return 0;
        },
        sendFinanceButtonClick(){
            
            var allData = {
                'category': this.specificCarGrade.carCategory, // categories = PASSENGER,SUV,LCV
                'model': this.specificCarGrade.model, // model = MAXIMA, ALTIMA
                'version_name': this.specificCarGrade.version_label, // grade_or_version = MAXIMA S
                'vehicleid': this.specificCarGrade.vehicle_id, // A36
                'language': this.selectedLang, // en or ar
                "button_name": "GET A FINANCE QUOTE",
                'new_downPayment': Number(parseFloat(this.downpayment.totalAmount).toFixed(2)).toLocaleString(),
                'new_insurance': Number(parseFloat(this.insurance.totalAmount).toFixed(2)).toLocaleString(),
                'new_profit': Number(parseFloat(this.bankProfit).toFixed(2)).toLocaleString(),
                'new_year': Number(parseFloat(this.model.tenureRange).toFixed(2)).toLocaleString(),
                'new_monthlyPayment': this.emi,
                'new_selectedCarPrice': Number(parseFloat(this.specificCarGrade.price).toFixed(2)).toLocaleString()
            };
            adobeAnalyticCode(allData);
            // window.location.href = "https://en-ae-az.dark.prod.heliosnissan.net/services/finance-request.html";

            window.open(
                'https://'+this.selectedLang+'.nissan-abudhabi.com/services/finance-request.html?modelName='+this.specificCarGrade.model
                
                +'&currency='+this.trans[this.selectedLang]['CURRENCY'] // A3
                +'&model='+this.specificCarGrade.vehicle_id // A3
                +'&grade='+this.specificCarGrade.version_label
                +'&dp=' +Math.round(this.downpayment.totalAmount)
                +'&insurance='+ Math.round(this.insurance.totalAmount)
                +'&price='+Math.round(this.specificCarGrade.price)
                +'&rate='+Math.round(this.bankProfit)
                +'&tenure='+this.model.tenureRange
                +'&emi='+Math.round(this.emi)
                +'&months=yes'
                ,
                '_parent' // <- This is what makes it open in a new window.
              );
        },
        downPaymentRangeSlider(){
            this.downpayment.totalAmount = (parseFloat(this.specificCarGrade.price) + parseFloat(this.insurance.totalAmount)) * this.model.downpaymentRange / 100;
            this.calculateMonthlyRepayment();
        },
        selectCar(vehicleId) {

            var mobile = this.isMobile()
            if(mobile){
                this.showCarModels = false;
            }
            
            this.specificCarGrade.vehicle_id = vehicleId;

            console.log(this.configuredCar[vehicleId], vehicleId)

            this.specificCarGrade.model = this.configuredCar[vehicleId].display;

            this.checkCarAvaiable = true;
            const allCars = this.cars;
            var specificCarData = allCars[vehicleId];

            this.specificCarVersion = specificCarData['version'];

            for (const item in specificCarData.version) {
                // console.log(item);
                if(specificCarData.version[item].active != 0){
                    var specificCarGradeId = item;
                    break;
                }
            }
            
            this.selectCarGrade(specificCarGradeId,'1','0');
        },
        selectCarGrade(specificCarGradeId,adobeAnaStatus,sendDropdownAdobeGrade){

            // console.log(specificCarGradeId,adobeAnaStatus,sendDropdownAdobeGrade, "-------")

            this.model.downpaymentRange = this.downpayment.min;
            this.specificCarGrade.key_index = specificCarGradeId;

            const specificCarGradeData = this.specificCarVersion;
            this.specificCarGrade.version_label = specificCarGradeData[specificCarGradeId].Version_Label;
            this.specificCarGrade.price = specificCarGradeData[specificCarGradeId].price;
            this.specificCarGrade.image = specificCarGradeData[specificCarGradeId].image;

            this.selectedDropdownModel = this.specificCarGrade.version_label;

            if(adobeAnaStatus == '1'){
                if(sendDropdownAdobeGrade == '2'){
                    this.gradeCheckAdobe = 1;
                }
                this.adobeAnalyticsSendData(this.specificCarGrade.vehicle_id);
            }

            //--- Start - Insurance Calculation with Tax Rate
            var insurance_amount;
            if(this.carRates.COMMERCIAL_VEHICLES.includes(this.specificCarGrade.vehicle_id)){
                
                // console.log(this.carRates.commercial_insurance_rate, "Commercial Vehicle Insurance Rate");
                insurance_amount = parseFloat(this.specificCarGrade.price * this.carRates.commercial_insurance_rate / 100);
            }else{

                insurance_amount = parseFloat(this.specificCarGrade.price * this.insurance.percentage / 100);
            }

            if(insurance_amount){
                var insurance_tax_amount = parseFloat(insurance_amount * this.insurance.tax_rate / 100);
                this.insurance.totalAmount = parseFloat(insurance_amount) + parseFloat(insurance_tax_amount);
            }

            // Set Min Insurance Amt if less than the given minimum_insurance_amount
            if(this.insurance.totalAmount <= this.insurance.minimum_amount){
                this.insurance.totalAmount = this.insurance.minimum_amount;
            }
            //--- End - Insurance Calculation with Tax Rate
            
            this.downPaymentRangeSlider();
            this.financeAmountCalculation();
            this.bankProfitCalculation();
            this.tenureRangeSlider();


            this.calculateMonthlyRepayment();
        },
        financeAmountCalculation(){
            this.financeAmount = (parseFloat(this.specificCarGrade.price) + parseFloat(this.insurance.totalAmount)) - this.downpayment.totalAmount;
        },
        bankProfitCalculation(){

            if(this.carRates.COMMERCIAL_VEHICLES.includes(this.specificCarGrade.vehicle_id)){
                
                // console.log(this.carRates.commercial_bank_rate, "Commercial Vehicle Bank Rate");
                this.bankProfit = (this.financeAmount * parseFloat(this.carRates.commercial_bank_rate) / 100) * (this.model.tenureRange / 12);
            }else{

                this.bankProfit = (this.financeAmount * parseFloat(this.carRates.bank_profit_rate) / 100) * (this.model.tenureRange / 12);
            }

        },
        tenureRangeSlider(){
            this.bankProfitCalculation();
            // this.emi = (parseFloat(this.bankProfit) + parseFloat(this.financeAmount)) / (this.model.tenureRange * 12);
            this.emi = (parseFloat(this.bankProfit) + parseFloat(this.financeAmount)) / this.model.tenureRange;
        },



        
        // ***********************************************************

        calculateMonthlyRepayment() {
            let amntLoan = parseFloat(this.specificCarGrade.price); // Loan amount
            let amntDeposit = parseFloat(this.downpayment.totalAmount); // Initial deposit
            let amntSubsidy = parseFloat(this.amount_subsidy); // No subsidy
            let amntExtras = parseFloat(this.extrasAmount); // No extra costs
            let amntInitiation = 1207.50; // No initiation fees
            let amntAdminFee = parseFloat(this.admin_fee); // No admin fees
            let intBalloon = parseInt(this.balloonRangePercentage); // No balloon payment
            let intRate = parseFloat(this.carRates.annual_interest_rate); // Annual interest rate
            let termLoan = parseFloat(this.model.tenureRange); // Loan term in years


            // let amntLoan = 61500; // Loan amount
            // let amntDeposit = 12700; // Initial deposit
            // let amntSubsidy = 0; // No subsidy
            // let amntExtras = 0; // Additional costs
            // let amntInitiation = 1207.50; // No initiation fees
            // let amntAdminFee = 69; // No admin fees
            // let intBalloon = 0; // No balloon payment
            // let intRate = 13; // Annual interest rate
            // let termLoan = 12; // Loan term in years
            let res = this.calculateMonthlyRepaymentAmount(
                amntLoan,
                amntDeposit,
                amntSubsidy,
                amntExtras,
                amntInitiation,
                amntAdminFee,
                intBalloon,
                intRate,
                termLoan
            );
        
            this.monthly_payment = res.monthly_payment;
            this.balloonAmount = res.balloon_amount;
            this.balance_to_finance = res.balance_to_finance;
            this.interest_paid = res.interest_paid;
        },

        calculateMonthlyRepaymentAmount(amntLoan, amntDeposit, amntSubsidy, amntExtras, amntInitiation, amntAdminFee, intBalloon, intRate, termLoan) {
            
            
            // console.log('amntLoan : ', amntLoan, ' amntDeposit : ', amntDeposit, ' amntSubsidy : ', amntSubsidy, ' amntExtras : ', amntExtras, ' amntInitiation : ', amntInitiation, ' amntAdminFee : ', amntAdminFee, ' intBalloon : ', intBalloon, ' intRate : ', intRate, ' termLoan : ', termLoan);
            const MONTHS_PER_YEAR = 12;
            const VALUE_HUNDRED = 100;
            let monthlyPayment = 0;

            const inMonthlyRate = intRate / VALUE_HUNDRED / MONTHS_PER_YEAR;
            const inLoanTerm = parseInt(termLoan);
            // Calculate the total loan amount
            const inDeposit = amntDeposit + amntSubsidy;
            const inLoan = amntLoan + amntExtras + amntInitiation - inDeposit;

             // Calculate the final loan amount after considering the balloon payment
            const inBalloonAmnt = (amntLoan) * intBalloon / VALUE_HUNDRED;
            const inFinalLoan = inLoan - (inBalloonAmnt / Math.pow(1 + inMonthlyRate, inLoanTerm));

            // Calculate the monthly payment using the formula for an annuity
            monthlyPayment = (inFinalLoan * inMonthlyRate) / (1 - Math.pow(1 + inMonthlyRate, -inLoanTerm)) + amntAdminFee;

            // Calculate the total paid over the term of the loan
            let total_paid = (monthlyPayment * parseFloat(termLoan));

            // Calculate the total interest paid over the term of the loan
            let interest_paid = (total_paid - inLoan + inBalloonAmnt) - (amntAdminFee * termLoan)

            let responseData = {
                'monthly_payment': monthlyPayment.toFixed(2),
                'balloon_amount': inBalloonAmnt.toFixed(2),
                'balance_to_finance': inLoan.toFixed(2),
                'total_paid': total_paid.toFixed(2),
                'interest_paid': interest_paid.toFixed(2)
            };
            // console.log(responseData);

            return responseData;
        },



        adobeAnalyticsSendData(){

            var carCategory = "";
            if(this.carRates.PASSENGER.includes(this.specificCarGrade.vehicle_id)){
                carCategory = 'PASSENGER CAR';
            }
            if(this.carRates.SUV.includes(this.specificCarGrade.vehicle_id)){
                carCategory = 'SUVS & CROSSOVERS';
            }
            if(this.carRates.SPORTS.includes(this.specificCarGrade.vehicle_id)){
                carCategory = 'SPORTS CARS';
            }
            if(this.carRates.COMMERCIAL_VEHICLES.includes(this.specificCarGrade.vehicle_id)){
                carCategory = 'COMMERCIAL VEHICLES';
            }

            this.specificCarGrade.carCategory = carCategory;
            var allData = {
                'category': this.specificCarGrade.carCategory,
                'version_name': this.gradeCheckAdobe ? this.specificCarGrade.version_label: null, // MAXIMA SV
                'model': this.specificCarGrade.model.toUpperCase(), // MAXIMA
                'vehicleid': this.specificCarGrade.vehicle_id, //eg: A36
                'language': this.selectedLang,
            };
            adobeAnalyticCode(allData);
        }
    }
}
</script>


<template>
  <div>
    <div v-if="resBody && Object.keys(vehicleRates).length != 0 && Object.keys(carConfig).length != 0">
      <FinanceCalculator
        v-bind:newcars="resBody"
        v-bind:vehiclerates="vehicleRates"
        v-bind:carConfiguration="carConfig"
      />
    </div>
  </div>
</template>

<script>
import FinanceCalculator from "./components/FinanceCalculator.vue";
import axios from "axios";

export default {
  name: "App",
  mounted() {

    document.title = 'NSA Finance Calculator - Nissan';

    // let uri = window.location.search.substring(1);
    // let params = new URLSearchParams(uri);

    axios
      .get(
        `https://nissan-modelcompare.com/compare-za/json/Nissan_compare_za_en.json`
      )
      .then((response) => {
        this.resBody = response;
      });


    axios.get(`./json/calculationConfig.json`).then((response) => {
      // console.log(response, "22222")
      this.vehicleRates = response.data;
    });

    axios.get(`./json/carConfiguration.json`).then((response) => {
      // console.log(response,"zzzz")
      this.carConfig = response.data;
    });
  },
  components: {
    FinanceCalculator,
  },
  data() {
    return {
      resBody: null,
      vehicleRates: {},
      carConfig: {},
    };
  },
};
</script>

<style>
@import "./assets/css/custom.css";
</style>
